import VideoPlayer from './utils/VideoPlayer';

interface Props {
  videoUrl: string;
  inactive?: boolean;
}

const CarouselVideoSlide = (props: Props) => {
  return <VideoPlayer {...props} autoplay={true} />;
};

export default CarouselVideoSlide;
